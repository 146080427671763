@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --radius: 0.5rem;

    --brand: #15135e;
    --brandsecondary: #44408a;
    --good: #49950d;
    --acceptable: #ecaa18;
    --critical: #cc3232;
    --info: #0e77d9;
    --black1: #000000;
    --black2: #28292d;
    --blueblack: #101828;
    --blueblack2: #4c525e;
    --grey1: #475569;
    --grey2: #808080;
    --grey3: #dfe5ec;
    --grey4: #f1f2f8;
  }
}

@layer components {
  .scrollbar-thin::-webkit-scrollbar,
  body::-webkit-scrollbar,
  .ps-sidebar-container::-webkit-scrollbar {
    width: 4px; /* Adjust the width of the scrollbar */
    height: 4px; /* Adjust the height of the scrollbar */
  }
  .scrollbar-thin::-webkit-scrollbar-track,
  body::-webkit-scrollbar-track,
  .ps-sidebar-container::-webkit-scrollbar-track {
    @apply rounded bg-grey3;
  }
  .scrollbar-thin::-webkit-scrollbar-thumb,
  body::-webkit-scrollbar-thumb,
  .ps-sidebar-container::-webkit-scrollbar-thumb {
    @apply rounded bg-grey1;
  }
  .scrollbar-thin::-webkit-scrollbar-thumb:hover,
  body::-webkit-scrollbar-thumb:hover,
  .ps-sidebar-container::-webkit-scrollbar-thumb:hover {
    @apply bg-black2;
  }
}

@layer components {
  *::-webkit-scrollbar {
    width: 6px; /* Adjust the width of the scrollbar */
    height: 6px; /* Adjust the height of the scrollbar */
  }
  *::-webkit-scrollbar-track {
    @apply rounded bg-[#f1f1f1];
  }
  *::-webkit-scrollbar-thumb {
    @apply rounded bg-[#c1c1c1];
  }
  *::-webkit-scrollbar-thumb:hover {
    @apply bg-[#c1c1c1];
  }
}

/* Scrollable css starts */

.SortableItem {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 0px 10px;
  /* box-shadow:
    0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
    0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15); */
  border-radius: calc(4px / var(--scale-x, 1));
  box-sizing: border-box;
  list-style: none;
  color: #333;
  font-weight: 400;
  font-size: 14px;
  /*TODO: found this place too, looks like it is possible to remove the whole component */
  font-family: sans-serif;
  height: 52px;
  background-color: #f9fafb;
  border-radius: 0px;
  border-top: 1px solid hsl(var(--info));
}

.DragHandle {
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  margin-top: -4px;
}

.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.DragHandle:focus-visible {
  box-shadow: 0 0px 0px 2px #4c9ffe;
}

.DragHandle svg {
  flex: 0 0 auto;
  margin: auto;
  height: 100%;
  overflow: visible;
  fill: #919eab;
}

/* Scrollable css ends */

/* react-dates css starts */

.DateRangePickerInput {
  border-radius: 8px !important;
  padding-left: 6px;
  padding-right: 8px;
}

.SingleDatePickerInput {
  border-radius: 8px !important;
}

.DateInput {
  border-radius: 8px;
}

.DateInput_input {
  font-size: 14px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 0;
  padding-right: 0;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: calc(100% - 16px);
  border-bottom: 0 !important;
  border-radius: 8px;
}

.DateRangePickerInput_arrow {
  margin-left: 4px;
  margin-right: 6px;
}

.DayPickerNavigation_button {
  border-radius: 8px !important;
}

.CalendarMonth_table {
  margin-top: 20px;
}

.CalendarDay__selected_start,
.CalendarDay__selected_end {
  background: var(--brandsecondary) !important;
  border: 0px !important;
}

.CalendarDay__selected_span {
  background: var(--grey4) !important;
  color: black !important;
  border: 0px !important;
}

.CalendarDay__selected {
  background: var(--brandsecondary) !important;
  border: 0px !important;
}

.CalendarDay__selected_start {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.CalendarDay__selected_end {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.CalendarDay {
  border: 0px !important;
}

.DateRangePickerInput > button {
  padding: 0px;
  margin: 0px;
  margin-left: 6px;
}

.DateInput_input__focused {
  border-bottom: 0 !important;
  box-shadow: 0px 1px 0px 0px var(--info) !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: transparent;
}

.CalendarMonth_table {
  margin-top: 5px;
}

/* react-dates css ends */

/* recharts-cartesian-grid for horizontal lines */
.rate-control-chart .recharts-cartesian-grid-horizontal line {
  stroke-dasharray: 0;
}

/* recharts-cartesian-grid for horizontal lines */
.rate-control-chart .recharts-cartesian-grid-vertical line {
  stroke-dasharray: 3 6;
}

.ps-submenu-expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ps-active {
  border-radius: 8px;
}
